<template>
  <div class="slidepage">
    <div class="moreNavTwo" v-if="stwleTwo"></div>
    <div class="moreNav" v-if="stwleTwo&&isAndroidPhone"></div>
    <!-- <div class="moreNav" v-if="stwleTwo"></div> -->
    <!-- 导航 -->
    <div class="navContent" :class="{'navContentTwo':stwleTwo}" :style="navStyle" id="navContent">
      <div class="slidnav" id="slidnav">
        <div
          class="slidnavContent"
          :class="{'choosed':boxindex==index}"
          v-for="(navitem,index) in myNavData"
          :key="index"
          @click="handovernavigation(index,true)"
        >
          {{navitem.navigationName}} {{navitem.columnNameApp}}
          <div class="indexContent" v-show="boxindex==index"></div>
        </div>
      </div>
      <div class="addNav" @click="editNav" v-if="navEdit">
        <i class="iconfont icon-caidan"></i>
      </div>
    </div>

    <!-- 导航更改 -->
    <div :class="{'navChange':true,'navChangeBlock':navBlock}">
      <div class="closeNavChange">
        <i class="iconfont icon-guanbi" @click="editNav"></i>
      </div>
      <div class="myNavCon">
        <div class="myNavConTitle">
          <p>我的频道</p>
          <button v-show="!myNavDataBlock" @click="myNavDataBlock=true">编辑</button>
          <button v-show="myNavDataBlock" @click="myNavDataBlock=false">完成</button>
        </div>
        <div
          class="myNavConList editMoveNav"
          id="editMoveNav"
          :style="{height:Math.ceil(myNavData.length/4)*40+'px'}"
        >
          <!-- @touchstart.stop="titleMoveStart($event,index,item)"
                    @touchmove.stop="titleMove($event,index,item)"
          @touchend.stop="titleMoveEnd($event,index,item)"-->
          <div
            class="myNavConListPer moveTitle"
            v-for="(item,index) in myNavData"
            :key="index"
            :style="{top:parseInt(index/4)*40+'px',left:parseInt(index%4)*26+'%'}"
          >
            <p class="myNavConListPerTitle">{{item.navigationName}}</p>
            <i
              class="iconfont icon-guanbi1 guanbi"
              v-show="myNavDataBlock&&item.subscriber!='tuijian'&&item.subscriber!='guanzhu'"
              @click="closePerNav(item,index)"
            ></i>
          </div>
        </div>
      </div>
      <!-- <div style="height:50px;width:100%;"></div> -->
      <div class="myNavCon">
        <div class="myNavConTitle">
          <p>
            推荐频道
            <span>点击添加频道</span>
          </p>
        </div>
        <div class="myNavConList">
          <div
            class="myNavConListPer"
            v-for="(item,index) in otherNavData"
            :key="index"
            @click="addNavPer(item,index)"
          >
            <p class="myNavConListPerTitle">{{item.navigationName}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播
            @touchstart="moveBoxstart($event,index)"
            @touchmove="moveBox($event,index)"
            @touchend="moveBoxend($event,index)"
    -->
    <div class="wheelplanting" id="wheelplanting" :class="{'wheelplantingTwo':stwleTwo}">
      <div class="slideBox" id="slideBox">
        <div
          class="slideBoxNav"
          v-for="(navitem,index) in myNavData"
          :key="index"
          @touchstart="moveBoxstart($event,index)"
          @touchmove="moveBox($event,index)"
          @touchend="moveBoxend($event,index)"
        >
          <slot :name="'slide'+index" v-if="navitem.blockTemplate"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'slidePage',
  props: {
    navData: {  //导航数据
      type: Array,
      default: () => [], //必须是一个函数
      required: true,
    },
    navStyle: {
      type: Object,
      default: () => { }, //必须是一个函数
      required: false,
    },
    navEdit: { //导航是否可编辑
      type: Boolean,
      default: () => false, //必须是一个函数
      required: false,
    },
    stwleTwo: {  //导航的第二种样式
      type: Boolean,
      default: () => false, //必须是一个函数
      required: false,
    },
    designatedLocation: { //指定显示位置
      type: Number,
      default: () => 0, //必须是一个函数
      required: false,
    }
  },
  data() {
    return {
      boxindex: 0, //轮播图的位置
      LastTime: 0, //上一次时间
      LastX: 0,  //上一次位移
      endTime: 0, //结束时间
      startX: 0,//开始位移 x轴
      startY: 0, //开始位移 y轴
      xielu: false, //是否是横向滑动 
      firstMove: 1, //第一次移动
      fastSpeed: false,//是否是快速
      left: false, //往左
      right: false, //往右
      wenyi: 0,
      lunboWidth: 0, //轮播图的宽

      navBlock: false,// 导航的显示隐藏

      myNavData: [], //我的频道
      myNavDataBlock: false, //我的频道按钮
      otherNavData: [], //其他频道

      // 标题移动
      titleMoveStartX: 0, //开始的x位移
      titleMoveStartY: 0, //开始的Y位移
      deviationX: 0,
      deviationY: 0,
      isAndroidPhone: true, //默认安卓机
    }
  },
  watch: {
    // 如果 `question` 发生改变，这个函数就会运行
    navData: {

      handler: function (newData) {
        console.log('111xixi', newData);
        if (newData.length > 0) {
          console.log('enen');

          this.navChangeAll(newData);
          this.$nextTick(() => {
            this.widthComplote();
          })
        }
      },
      immediate: true
    },
    // 给指定位置
    designatedLocation: {
      handler: function (newLocation) {
        // 移动位置 指定显示到当前位置
        if (this.boxindex != newLocation) {

          if (this.myNavData.length > 0) {
            this.myNavData[newLocation].blockTemplate = true;
            this.boxindex = newLocation; //位置
            this.handovernavigation(newLocation, false);
          }


        }
      },
      immediate: false
    }
  },
  created() {
    this.isAndroidPhone = this.judgetPhone(); //判断是安卓还是苹果
  },
  methods: {
    // 开始移动 标题托拽
    titleMoveStart(ev, index) {
      this.titleMoveStartX = ev.touches[0].clientX; //开始的x位移
      this.titleMoveStartY = ev.touches[0].clientY; //开始的Y位移

      var moveTitle = document.getElementsByClassName("moveTitle")[index];
      this.deviationX = moveTitle.offsetLeft; //起始位置
      this.deviationY = moveTitle.offsetTop;
    },
    // 移动 标题移动
    titleMove(ev, index) {
      var myEle = document.getElementsByClassName("moveTitle")[index];
      var distanceX = (event.touches[0].clientX - this.titleMoveStartX);//x轴位移
      var distanceY = (event.touches[0].clientY - this.titleMoveStartY);//y轴位移
      myEle.style.left = this.deviationX + distanceX + 'px';
      myEle.style.top = this.deviationY + distanceY + 'px';
    },
    // 结束移动 放置div
    titleMoveEnd() {
      // console.log(ev);
    },
    widthComplote() {
      var allWidth = document.getElementById("wheelplanting");
      this.lunboWidth = allWidth.clientWidth;
      var slidebox = document.getElementById("slideBox"); //轮播的总宽
      slidebox.style.width = allWidth.clientWidth * this.myNavData.length + "px";
      var sonBox = document.getElementsByClassName('slideBoxNav');
      for (let i = 0; i < sonBox.length; i++) { //每一个的宽
        sonBox[i].style.width = allWidth.clientWidth + "px";
      }
      //导航可编辑
      this.$emit("navChange", this.myNavData);
    },
    // 取导航数据的前6个
    navChangeAll(newData) {

      this.navData = newData
      console.log('haha', this.navData);
      if (this.navEdit) {
        // if(localStorage.myNavData&&localStorage.otherNavData&&(this.navData.length==(JSON.parse(localStorage.myNavData).length+JSON.parse(localStorage.otherNavData).length))){ //如果缓存仲有数据 拿缓存数据
        //     this.myNavData=JSON.parse(localStorage.myNavData);
        //     this.otherNavData=JSON.parse(localStorage.otherNavData);
        // }else{  
        //没有缓存数据从上级拿

        if (this.navData.length > 6) { //默认显示前6条数据
          this.myNavData = this.navData.slice(0, 6) //我的频道
          this.otherNavData = this.navData.slice(6, this.navData.length);
        } else {
          this.myNavData = this.navData; //不需要编辑导航
          this.otherNavData = [];
        }
        // localStorage.myNavData=JSON.stringify(this.myNavData);
        // localStorage.otherNavData=JSON.stringify(this.otherNavData);
        // }
      } else {
        this.myNavData = this.navData; //不需要编辑导航
        console.log(' this.myNavData', this.myNavData);
      }

      this.slideJudget(); //加载判断
      // 移动位置 指定显示到当前位置
      if (this.boxindex != this.designatedLocation) {
        this.myNavData[this.designatedLocation].blockTemplate = true;
        this.boxindex = this.designatedLocation; //位置
        this.handovernavigation(this.designatedLocation, false);
      }
      // console.log(this.myNavData);
    },
    slideJudget() {
      for (var i = 0; i < this.myNavData.length; i++) {
        if (i == 0) {
          this.myNavData[i].blockTemplate = true;
        } else {
          this.myNavData[i].blockTemplate = false;
        }
      }
    },
    // 导航编辑框
    closePerNav(item, index) {
      this.otherNavData.push(item);
      this.myNavData.splice(index, 1);
      // localStorage.myNavData=JSON.stringify(this.myNavData);
      // localStorage.otherNavData=JSON.stringify(this.otherNavData);
      this.$nextTick(() => {
        this.widthComplote();
        this.handovernavigation(this.designatedLocation, false);
      })
    },
    addNavPer(item, index) {
      item.blockTemplate = false; //是否加载当前组件
      this.myNavData.push(item);
      this.otherNavData.splice(index, 1);
      // localStorage.myNavData=JSON.stringify(this.myNavData);
      // localStorage.otherNavData=JSON.stringify(this.otherNavData);
      this.$nextTick(() => {
        this.widthComplote();
      })
    },
    editNav() {
      this.navBlock = !this.navBlock;
    },
    // 切换导航
    handovernavigation(index, sign) {
      if (this.myNavData[index].navigationUrl) { // 外链
        window.location.href = this.myNavData[index].navigationUrl;
        return false;
      }
      this.$nextTick(() => {
        var allWidth = document.getElementById("wheelplanting"); //导航
        this.myNavData[index].blockTemplate = true;
        this.boxindex = index;   //当前选中
        if (sign) {
          this.addTransition();  //过度效果
        } else {
          this.removeTransition();
        }
        this.transformMove(-index * allWidth.clientWidth); //不移动
        // if(index==this.myNavData.length-1){
        //     this.navindexChange(index-1,false);
        // }else{
        this.navindexChange(index, false);
        // }
      })
    },
    // 加动画
    transformMove(translateX) {
      var slideBox = document.getElementById("slideBox");
      slideBox.style.transform = 'translateX(' + translateX + 'px)';
      slideBox.style.webkitTransform = 'translateX(' + translateX + 'px)';
    },
    //过度效果
    addTransition() {
      var slideBox = document.getElementById("slideBox");
      slideBox.style.transition = 'all 0.2s';
      slideBox.style.webkitTransition = 'all 0.2s';
    },
    // 移除过度效果
    removeTransition() {
      var slideBox = document.getElementById("slideBox");
      slideBox.style.transition = 'none';
      slideBox.style.webkitTransition = 'none';
    },
    // 获取每一个轮播的width

    // 移动轮播图
    moveBoxstart(event) {
      this.LastTime = Date.now(); //上一次时间
      this.LastX = event.touches[0].clientX;  //上一次位移
      this.fastSpeed = false; //速度快还是慢
      this.left = false; //往左
      this.right = false; //往右
      this.wenyi = 0;

      this.xielu = false;
      this.startX = event.touches[0].clientX; //开始位移
      this.startY = event.touches[0].clientY;
      this.firstMove = 1;
      // console.log("111");
    },
    // 阻止移动
    StopMoving(index, sign) {
      var width = this.lunboWidth; //宽度
      if (sign == 1) { //左移动
        if (this.myNavData[index + 1].navigationUrl) {
          window.location.href = this.myNavData[index + 1].navigationUrl;
          this.transformMove(-index * width);
          return false;
        }
      } else if (sign == 2) { //右移动
        if (this.myNavData[index - 1].navigationUrl) {
          window.location.href = this.myNavData[index - 1].navigationUrl;
          this.transformMove(-index * width);
          return false;
        }
      }
      return true;
    },
    // 移动轮播图
    moveBox(event, index) {
      var width = this.lunboWidth; //宽度

      if (this.LastTime && this.LastX) {
        // console.log(index)
        this.wenyi = event.touches[0].clientX - this.LastX; //大于0往右小于0往左
        var noeweiyi;
        if (this.wenyi < 0) { //左右移动
          noeweiyi = -this.wenyi;
          this.left = true; //往右

          // console.log()
          // if(this.myNavData[index + 1].title == '微博') {
          //     return this._isWeiBo(index + 1);
          // }

        } else if (this.wenyi > 0) {
          noeweiyi = this.wenyi;
          this.right = true; //往左

          // if(this.myNavData[index - 1].title == '微博') {
          //     return this._isWeiBo(index - 1);
          // }
        }
        var time = Date.now() - this.LastTime;
        if (noeweiyi / time > 0.1) {
          this.fastSpeed = true; //快速
        } else {
          this.fastSpeed = false; //速度慢
        }
      }

      // 保留上一次的时间和速度
      this.LastTime = Date.now(); //上一次时间
      this.LastX = event.touches[0].clientX;  //上一次位移

      /*计算位移  有正负方向*/
      var distanceX = (event.touches[0].clientX - this.startX);

      var distanceY = event.touches[0].clientY - this.startY;
      var distanceXmore;
      if (distanceX < 0) {
        distanceXmore = -distanceX;
      } else {
        distanceXmore = distanceX;
      }
      if (distanceY < 0) {
        distanceY = -distanceY;
      }
      if (distanceXmore >= distanceY && this.firstMove == 1) {
        this.xielu = true;
      }
      this.firstMove++;

      /*计算目标元素的位移  不用管正负*/
      /*元素将要的定位=当前定位+手指移动的距离*/
      this.removeTransition();
      if (this.xielu) {
        this.translateX = -index * width + distanceX;
        if ((distanceX > 0 && index == 0) || (index == this.myNavData.length - 1 && distanceX < 0)) { //第一个跟最后一个不移动
          this.translateX = -index * width;
        }
        /*滑动--->元素随着手指的滑动做位置的改变*/
        this.transformMove(this.translateX);
      }

    },
    // 移动轮播图
    moveBoxend(event, index) {
      if (this.xielu) {
        /*滑动--->元素随着手指的滑动做位置的改变*/
        var width = this.lunboWidth; //宽度
        var moveX = event.changedTouches[0].clientX;
        /*计算位移  有正负方向*/
        var distanceX = moveX - this.startX;
        if ((distanceX > 0 && index == 0) || (index == this.myNavData.length - 1 && distanceX < 0)) { //第一个跟最后一个不移动
          this.transformMove(-index * width); //不移动
        } else {
          this.addTransition();
          if (this.fastSpeed) {
            if (this.left && this.right) {
              if (distanceX > 0 && this.wenyi > 0) { //往右移动
                if (this.StopMoving(index, 2)) {
                  this.transformMove(-parseInt(index - 1) * width);
                  this.navindexChange(index - 1, true);
                }
              } else if (distanceX < 0 && this.wenyi < 0) {  //往左移动
                if (this.StopMoving(index, 1)) {
                  this.transformMove(-parseInt(index + 1) * width);
                  this.navindexChange(index + 1, true);
                }
              } else {
                this.transformMove(-index * width); //不移动
              }
            } else {
              if (distanceX > 0) { //往右移动
                if (this.StopMoving(index, 2)) {
                  this.transformMove(-parseInt(index - 1) * width);
                  this.navindexChange(index - 1, true);
                }
              } else if (distanceX < 0) {  //往左移动
                if (this.StopMoving(index, 1)) {
                  this.transformMove(-parseInt(index + 1) * width);
                  this.navindexChange(index + 1, true);
                }
              }
            }
          } else {
            var nowdistanceX = distanceX;
            if (nowdistanceX < 0) {
              nowdistanceX = -distanceX;
            }
            if (nowdistanceX > width / 2) {
              if (distanceX > 0) { //往右移动
                if (this.StopMoving(index, 2)) {
                  this.transformMove(-parseInt(index - 1) * width);
                  this.navindexChange(index - 1, true);
                }
              } else if (distanceX < 0) {  //往左移动
                if (this.StopMoving(index, 1)) {
                  this.transformMove(-parseInt(index + 1) * width);
                  this.navindexChange(index + 1, true);
                }
              }
            } else {
              this.transformMove(-index * width); //不移动
            }
          }
        }
      }
    },
    //滑动导航
    navindexChange(index, isIndex) {
      if (isIndex) {
        this.myNavData[index].blockTemplate = true;
        this.boxindex = index;
      }
      this.$emit('indexChanged', this.boxindex); //发送数据
      // 使标题显示在屏幕内
      var allWidth = document.getElementById("slidnav");
      var nowChoosed = document.getElementsByClassName("slidnavContent");
      var sum = 0;
      // 前面的距离
      for (var i = 0; i < index; i++) {
        sum = sum + nowChoosed[i].clientWidth + 24; //左边距离
      }
      var myWidth = nowChoosed[index].clientWidth + 24; //我的距离
      var left = (allWidth.clientWidth - myWidth) / 2;
      allWidth.scrollLeft = sum - left;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slidepage {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  /* background: red; */
}
.slidepage ::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.moreNav {
  height: 24px;
  width: 100%;
  background: #1377e2;
}

.moreNavTwo {
  width: 100%;
  background: #1377e2;
}
.moreNavTwo {
  padding-top: env(safe-area-inset-top);
  /* padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right); 
		padding-bottom: env(safe-area-inset-bottom);  */
}
/* @supports(padding: max(0px)) {
		.moreNavTwo {
			padding-top: max(20px, env(safe-area-inset-left));
		}
	} */
.navChange {
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  background: #ffffff;
  transition: all 0.2s;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 999;
  box-sizing: border-box;
}
.navChangeBlock {
  top: 0px;
}
.closeNavChange {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: right;
  box-sizing: border-box;
  padding: 0px 10px;
  box-sizing: border-box;
}
.myNavCon {
  width: 100%;
  /* overflow: hidden; */
  padding: 0px 10px;
  box-sizing: border-box;
}
.myNavConTitle {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
.myNavConTitle p {
  font-weight: 600;
}
.myNavConTitle button {
  background: #ffffff;
  border: 1px solid rgb(250, 71, 71, 0.5);
  color: #1377e2;
  padding: 4px 12px;
  border-radius: 100px;
}
.myNavConList {
  width: 100%;
  /* margin-bottom: 10px; */
}
.editMoveNav {
  position: relative;
}
.myNavConListPer {
  height: 30px;
  float: left;
  width: 22%;
  background: #eee;
  margin-right: 4%;
  text-align: center;
  line-height: 30px;
  position: relative;
}
.moveTitle {
  position: absolute;
}
.myNavConListPerTitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.guanbi {
  position: absolute;
  top: -12px;
  left: -6px;
  font-size: 14px;
}
.myNavConListPer:nth-child(4n) {
  margin-right: 0px;
  margin-bottom: 10px;
}
.closeNavChange .iconfont {
  font-size: 20px;
}
.navContent {
  width: 100%;
  height: 0.6rem;
  padding: 0.2rem 0rem 0rem 0rem;
}
.navContentTwo {
  background: #1377e2;
}
.slidnav {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  /* justify-content: space-around; */
}
.addNav {
  width: 40px;
  height: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #ddd;
  color: #1377e2;
}
.slidnav::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.slidnavContent {
  height: 100%;
  line-height: 0.4rem;
  color: #616a72;
  font-size: 0.31rem;
  display: inline-block;
  position: relative;
  margin-left: 0.32rem;
}

/* @keyframes myfirst
    {
        from {
            width: 0px;
            left: 50%;
        }
        to {
            width: 100%;
            left: 0px;
        }
    } */
.indexContent {
  position: absolute;
  bottom: 0;
  left: 0px;
  height: 0.4rem;
  width: 100%;
  border-bottom: 0.05rem solid #1377e2;
  /* animation: myfirst 0.5s; */
}
.choosed {
  color: #1377e2;
  font-weight: 700;
}
.navContentTwo .choosed {
  color: #fff;
}
.navContentTwo .slidnavContent {
  color: #fff;
}
.navContentTwo .indexContent {
  border-bottom: 2px solid #fff;
}
.wheelplanting {
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
  /* position: relative; */
}
.wheelplantingTwo {
  height: calc(100% - 64px);
}
.slideBox {
  /* position: absolute;
        top: 0px;
        left: 0px; */
  height: 100%;
  display: flex;
}
.slideBoxNav {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>